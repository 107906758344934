import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Login from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import Collapse from '@mui/material/Collapse';
import { logo } from './APIRequests.js';
import CustomizedMenu from '../../components/header/CustomizedMenu.js';
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LogoutConfirmationDialog from '../../components/LogoutConfirmationDialog.js';
import { checkSessionStorageItem, clearUserInfo } from '../../utils/sessionStorage.js';
import {
  SESSION_STORAGE_CLIENT,
  SESSION_STORAGE_EMAIL,
  SESSION_STORAGE_FIRSTNAME,
  SESSION_STORAGE_INITIALS,
  SESSION_STORAGE_IS_LOGGED_IN,
  SESSION_STORAGE_LASTNAME
} from '../../utils/constants.js';

const iconButtonStyles = {
  color: '#A2AED2',
  minWidth: '35px',
  border: 'none',
  width: '35px',
  height: '35px',
  fontSize: '16px',
  marginRight: '10px'
};

const avatarStyles = {
  bgcolor: '#FEA250',
  cursor: 'pointer',
  width: 36,
  height: 36,
  fontSize: 16
};

function ResponsiveAppBar() {
  const { data, isLoading } = useQuery('logo', logo);
  const navigate = useNavigate();

  const isLoggedIn = sessionStorage.getItem(SESSION_STORAGE_IS_LOGGED_IN);

  const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);

  const navigateToPlan = hasClient ? `${client}/plans` : `/plans`;
  const navigateToHome = hasClient ? `${client}` : `/`;

  const pages = [
    {
      name: 'About',
      submenus: [
        { name: 'About us', href: '/about-us' },
        { name: 'Why Choose', href: '/why-choose' }
      ]
    },
    {
      name: 'Services',
      submenus: [
        { name: 'All Services', href: '/services' },
        // { name: 'Mobile App', href: '/services/mobile-app' },
        {
          name: 'Extended Travel Program',
          href: '/services/extended-travel-program'
        },
        { name: 'Pet Assistance', href: '/services/pet-assistance' },
        { name: 'J1 Visa Services', href: '/services/j1-visa' }
      ]
    },
    { name: 'Plans', href: navigateToPlan },
    { name: 'Travel Blog', href: '/blogs' },
    { name: 'FAQ', href: '/faq' },
    { name: 'Contact Us', href: '/contact-us' }
  ];

  if (isLoggedIn) {
    // Find index of the 'Plans' object
    const indexToRemove = pages.findIndex((page) => page.name === 'Plans');

    // Remove the 'Plans' object from the array
    if (indexToRemove !== -1) {
      pages.splice(indexToRemove, 1);
    }
  }

  const handleNavigate = (href) => {
    navigate(href);
    setOpen(false);
    setCollapsed({});
  };

  const [user, setUser] = React.useState({});
  const email = sessionStorage.getItem(SESSION_STORAGE_EMAIL);

  const updateUser = () => {
    setUser({
      email: email,
      initials: sessionStorage.getItem(SESSION_STORAGE_INITIALS),
      firstName: sessionStorage.getItem(SESSION_STORAGE_FIRSTNAME),
      lastName: sessionStorage.getItem(SESSION_STORAGE_LASTNAME)
    });
  };

  React.useEffect(() => {
    updateUser();

    // Listen for changes in session storage
    window.addEventListener('storage', updateUser);

    return () => window.removeEventListener('storage', updateUser);
  }, [email]);

  const [open, setOpen] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState({});

  const [openLogoutDialog, setOpenLogoutDialog] = React.useState(false);

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  const collapseSubMenu = (index) => {
    setCollapsed((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleLogout = () => {
    clearUserInfo();
    navigate('/');
    setOpenLogoutDialog(false);
    setOpen(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleProfileClick = () => {
    navigate('/profile');
    setOpen(false);
  };

  const DrawerList = (
    <Box sx={{ width: 300 }} role="presentation">
      {isLoggedIn && (
        <>
          <Grid container columnSpacing={2} p={2} alignItems="center" onClick={handleProfileClick}>
            <Grid item>
              <Avatar sx={avatarStyles}>{user.initials}</Avatar>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  fontWeight: 500,
                  color: '#10182F'
                }}>
                {user.firstName + ' ' + user.lastName}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  color: '#5A6475'
                }}>
                {user.email}
              </Typography>
            </Grid>
          </Grid>
          <Divider></Divider>
        </>
      )}
      <LogoutConfirmationDialog
        open={openLogoutDialog}
        handleClose={handleCloseLogoutDialog}
        handleLogout={handleLogout}
      />
      <List>
        {pages.map((page, index) =>
          page.submenus ? (
            <Grid key={page.name}>
              <ListItemButton
                onClick={() => collapseSubMenu(index)}
                sx={{
                  fontWeight: '500',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  color: '#10182F'
                }}>
                {page.name}
                {collapsed[index] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={collapsed[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {page.submenus.map((submenu) => (
                    <ListItemButton
                      key={submenu.name}
                      sx={{
                        pl: 4,
                        fontWeight: '500',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        color: '#5A6475'
                      }}>
                      <ListItemText
                        primary={submenu.name}
                        onClick={() => handleNavigate(submenu.href)}
                      />
                    </ListItemButton>
                  ))}
                </List>
                <Divider></Divider>
              </Collapse>
            </Grid>
          ) : (
            <ListItemButton
              key={page.name}
              onClick={() => handleNavigate(page.href)}
              sx={{
                fontWeight: '500',
                fontFamily: 'Inter',
                fontSize: '14px',
                color: '#10182F'
              }}>
              {page.name}
            </ListItemButton>
          )
        )}
        <Divider sx={{ paddingTop: '8px' }}></Divider>
        <ListItemButton
          onClick={() => (isLoggedIn ? setOpenLogoutDialog(true) : handleNavigate('/login'))}
          sx={{
            fontWeight: '500',
            fontFamily: 'Inter',
            fontSize: '14px',
            color: isLoggedIn ? '#E8616B' : '#5CB5A2'
          }}>
          {isLoggedIn ? (
            <Logout sx={{ marginRight: '8px' }} />
          ) : (
            <Login sx={{ marginRight: '8px' }} />
          )}
          {isLoggedIn ? 'Log out' : 'Log in'}
        </ListItemButton>
      </List>
    </Box>
  );

  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const appBarStyles = {
    borderBottom: isHomePage || isMobile ? 'none' : '1px solid #E9EBEF'
  };

  return (
    !isLoading && (
      <AppBar position="absolute" color={'transparent'} elevation={0} sx={appBarStyles}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Link to={navigateToHome}>
              <Box
                component="img"
                alt="Global Travel Plus"
                src={process.env.REACT_APP_BACKEND_URL + data?.logo}
                sx={{ height: '50px', marginTop: '3px' }}
              />
            </Link>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'end'
              }}>
              <IconButton sx={iconButtonStyles} size="large" onClick={toggleDrawer(true)}>
                <MenuIcon color="custom1" />
              </IconButton>

              <Drawer open={open} anchor="right" onClose={toggleDrawer(false)}>
                {DrawerList}
              </Drawer>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex', justifyContent: 'right' }
              }}>
              {pages.map((page) =>
                page.submenus ? (
                  <CustomizedMenu
                    name={page.name}
                    key={page.name}
                    items={page.submenus}
                    onClick={handleNavigate}
                  />
                ) : (
                  <Button
                    variant="menuButton"
                    key={page.name}
                    to={page.href}
                    onClick={() => handleNavigate(page.href)}>
                    {page.name}
                  </Button>
                )
              )}
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                ml: 10,
                display: { xs: 'none', md: 'flex', justifyContent: 'right' }
              }}>
              {!isLoggedIn ? (
                <Button href="/login" variant="outlined" sx={{ padding: '8px 16px' }}>
                  Log in
                </Button>
              ) : (
                <Tooltip
                  title={user.email}
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: '14px',
                        padding: '10px',
                        fontFamily: 'Inter'
                      }
                    }
                  }}>
                  <Link to="/profile" style={{ textDecoration: 'none' }}>
                    <Avatar sx={avatarStyles}>{user.initials}</Avatar>
                  </Link>
                </Tooltip>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    )
  );
}

export default ResponsiveAppBar;
