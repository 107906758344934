import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { COLORS } from '../../../../utils/colors';
import { Select } from '../../../../components/form/Select';
import { TextInput } from '../../../../components/form/TextField';
import { DateInput } from '../../../../components/form/DateInput';
import ReactPhoneInput from 'react-phone-input-material-ui';
import 'react-phone-input-material-ui/lib/style.css';
import { useQuery } from 'react-query';
import { validateEmail } from '../../wizard.api';
import { validationSchema1 } from './step1.scheme';
import { ActionButtonsContainer, CenterFormGrid } from '../../Wizard.styles';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import _ from 'lodash';
import { calculateAge, calculateAgeLimit, maskEmail, maskName } from '../../../../utils/wizard';
import { GOOGLE_ANALYTICS_LABELS, sendEvent, trackPageview } from '../../../../utils/analytics';
import useTrackScrollDepth from '../../../../hooks/useTrackScrollDepth';
import { PLAN_RENEW_MODE, PLAN_UPADTE_MODE } from '../../../../utils/constants';
import * as Sentry from '@sentry/react';

const Step1 = ({ formik, handleSubmit, shouldShow, ageLimit, pickerOptions, countries, mode }) => {
  const { customLabel } = shouldShow;
  const { min_age, max_age } = ageLimit || {};
  const { customer_professional_suffix, customer_suffix, customer_title } = pickerOptions;

  const isUserInUpdateMode = mode === PLAN_UPADTE_MODE;
  const isUserInRenewMode = mode === PLAN_RENEW_MODE;

  const hasError = !_.isEmpty(formik.errors);
  const [stepError, setStepError] = useState('');

  const {
    isFetching,
    error: emailError,
    refetch
  } = useQuery('validateEmail', () => validateEmail(formik.values.email), {
    enabled: false,
    retry: false,
    onError: (error) => {
      Sentry.captureException(new Error('Error in Validating email - Error captured'), {
        extra: {
          errorLevel: 'Email Validation',
          error: error,
          errorMessage: error?.response?.data?.error,
          errorStatus: error?.response?.status,
          errorStatusText: error?.statusText,
          userEmail: maskEmail(formik.values?.email),
          userName: maskName(formik.values?.firstName),
          userLastName: maskName(formik.values?.lastName)
        }
      });

      if (error?.response?.status === 500) {
        formik.setFieldError('email', 'Email Verification Failed. Please try again later.');
      } else {
        // Handle other errors (e.g., 400: email already in use)
        formik.setFieldError('email', error?.response?.data?.error || 'Unknown error');
      }
    },
    onSuccess: (data) => {
      if (!data?.error) {
        formik.setFieldError('email', '');
      } else {
        Sentry.captureException(
          new Error('Error in Validating email - Error captured on Success'),
          {
            extra: {
              errorLevel: 'Email Validation - on Success',
              error: data,
              userEmail: maskEmail(formik.values?.email),
              userName: maskName(formik.values?.firstName),
              userLastName: maskName(formik.values?.lastName)
            }
          }
        );

        formik.setFieldError('email', data?.error);
      }
    }
  });

  useEffect(() => {
    trackPageview(GOOGLE_ANALYTICS_LABELS.wizardStep1);
  }, []);

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.wizardStep1.name);

  const checkEmailAvailability = async (value) => {
    const isValidEmail = await validationSchema1(customLabel).fields.email.isValid(value);

    if (isValidEmail) {
      refetch();
    }
  };

  const { minBirthDate, maxBirthDate } = calculateAgeLimit(max_age, min_age);

  const handleSubmitFirstStep = async (values) => {
    await refetch();
    const validateFormRes = await formik.validateForm();
    const isValid = _.isEmpty(validateFormRes);

    if (isValid && formik.dirty) {
      const isEmailAvailable = !emailError;

      if (isEmailAvailable) {
        const age = calculateAge(formik.values.dateOfBirth);
        sendEvent('Date_Picker_Click', 'Main_Applicant_Age', age);
        handleSubmit(values);
      } else {
        formik.setFieldError('email', emailError?.response?.data?.error);
        formik.setTouched({ email: true });

        formik.setErrors({
          ...formik.errors,
          email: emailError?.response?.data?.error
        });
      }
    }

    if (isValid && !formik.dirty) {
      handleSubmit(values);
    } else {
      setStepError(false);

      const touchedFields = Object.keys(validationSchema1(customLabel).fields).reduce(
        (acc, key) => {
          acc[key] = true;

          return acc;
        },
        {}
      );

      formik.setTouched(touchedFields);
      setStepError(true);
      window.scrollTo(0, 0);
    }
  };

  const onPhoneChange = (value, country) => {
    formik.setFieldValue('countryDialCode', country.dialCode);
    formik.setFieldValue('countryIsoCode', country.countryCode.toUpperCase());
    formik.setFieldValue('phoneNumber', formatPhoneNumberIntl(`+${value}`));
  };

  const handleCountryChange = (event) => {
    const countrySelected = countries.find((country) => country.tid === event.target.value);

    const country = { name: countrySelected.name, id: countrySelected.tid };

    formik.setFieldValue('country', country);
    formik.setFieldValue('state', '');
  };

  return (
    <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
      <CenterFormGrid>
        <Grid mb={3}>
          {hasError && stepError && (
            <Grid mb={0.8}>
              <Typography variant="body" fontWeight={500} color={COLORS.error}>
                Please ensure all fields are filled in before proceeding.
              </Typography>
            </Grid>
          )}
          <Typography variant="body" fontWeight={700} color={COLORS.black}>
            Your Info
          </Typography>
        </Grid>
        <Grid mt={2}>
          <Select
            htmlFor="title"
            name="title"
            label="Title (Optional)"
            title="Title (Optional)"
            variant="filled"
            disableUnderline
            onBlur={formik.handleBlur}
            value={formik.values.title?.id || ''}
            error={formik?.touched?.title && Boolean(formik?.errors?.title)}
            helperText={formik?.touched?.title && formik?.errors?.title}
            onChange={(event) => {
              const titleSelected = customer_title.find((title) => title.id === event.target.value);
              formik.setFieldValue('title', titleSelected);
            }}
            renderValue={(selected) => {
              const selectedTitle = customer_title.find((title) => title.id === selected);

              return <div>{selectedTitle ? `${selectedTitle?.name} ` : ''}</div>;
            }}>
            <MenuItem value="">None</MenuItem>
            {customer_title.map(({ id, name }, index) => (
              <MenuItem key={index} value={id}>
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid mt={2}>
          <TextInput
            fullWidth
            label="First Name"
            id="firstName"
            name="firstName"
            disabled={isUserInUpdateMode}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik?.touched?.firstName && Boolean(formik?.errors?.firstName)}
            helperText={formik?.touched?.firstName && formik?.errors?.firstName}
          />
        </Grid>
        <Grid mt={2}>
          <TextInput
            fullWidth
            label="Middle Name (Optional)"
            id="middleName"
            name="middleName"
            value={formik.values.middleName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik?.touched?.middleName && Boolean(formik?.errors?.middleName)}
            helperText={formik?.touched?.middleName && formik?.errors?.middleName}
          />
        </Grid>
        <Grid mt={2}>
          <TextInput
            fullWidth
            label="Last Name"
            id="lastName"
            name="lastName"
            disabled={isUserInUpdateMode}
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik?.touched?.lastName && Boolean(formik?.errors?.lastName)}
            helperText={formik?.touched?.lastName && formik?.errors?.lastName}
          />
        </Grid>

        <Grid mt={2} display={'flex'} flexDirection={'row'} gap={1}>
          <Grid item flex={1}>
            <Select
              fullWidth
              label="Suffix (Optional)"
              id="suffix"
              name="suffix"
              onBlur={formik.handleBlur}
              value={formik.values.suffix?.id || ''}
              error={Boolean(formik?.errors?.suffix)}
              helperText={formik?.errors?.suffix}
              onChange={(event) => {
                const titleSelected = customer_suffix.find(
                  (title) => title.id === event.target.value
                );

                formik.setFieldValue('suffix', titleSelected);
              }}
              renderValue={(selected) => {
                const selectedTitle = customer_suffix.find((title) => title.id === selected);

                return <div>{selectedTitle ? `${selectedTitle?.name} ` : ''}</div>;
              }}>
              <MenuItem value="">None</MenuItem>
              {customer_suffix.map(({ name, id }, index) => (
                <MenuItem key={index} value={id}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item flex={1}>
            <Select
              htmlFor="professionalSuffix"
              name="professionalSuffix"
              label="Professional Suffix (Optional)"
              title="professionalSuffix"
              variant="filled"
              onBlur={formik.handleBlur}
              disableUnderline
              value={formik.values.professionalSuffix?.id || ''}
              error={Boolean(formik?.errors?.professionalSuffix)}
              helperText={formik?.errors?.professionalSuffix}
              onChange={(event) => {
                const titleSelected = customer_professional_suffix.find(
                  (title) => title.id === event.target.value
                );

                formik.setFieldValue('professionalSuffix', titleSelected);
              }}
              renderValue={(selected) => {
                const selectedTitle = customer_professional_suffix.find(
                  (title) => title.id === selected
                );

                return <div>{selectedTitle ? `${selectedTitle?.name} ` : ''}</div>;
              }}>
              <MenuItem value="">None</MenuItem>
              {customer_professional_suffix.map(({ id, name }, index) => (
                <MenuItem key={index} value={id}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid mt={2}>
          <ReactPhoneInput
            enableSearchField={true}
            value={formik.values.phoneNumber}
            label={''}
            onChange={(value, country) => onPhoneChange(value, country)}
            component={TextField}
            placeholder={'8023456789'}
            onBlur={formik.handleBlur}
            localization={'en'}
            country={'us'}
            autoFormat={true}
            countryCodeEditable={true}
            name="phoneNumber"
            id="phoneNumber"
            fullWidth
            inputProps={{
              variant: 'outlined',
              name: 'phoneNumber',
              id: 'phoneNumber',
              required: true
            }}
          />
          {formik?.touched?.phoneNumber && (
            <FormHelperText>{formik.errors.phoneNumber}</FormHelperText>
          )}
        </Grid>

        <Grid mt={2}>
          <DateInput
            fullWidth
            onBlur={formik.handleBlur}
            label="Date of Birth"
            id="dateOfBirth"
            name="dateOfBirth"
            disabled={isUserInUpdateMode}
            value={formik.values.dateOfBirth}
            onChange={(date) => formik.setFieldValue('dateOfBirth', date)}
            error={formik?.touched?.dateOfBirth && Boolean(formik?.errors?.dateOfBirth)}
            helperText={formik?.touched?.dateOfBirth && formik?.errors?.dateOfBirth}
            minDate={minBirthDate}
            maxDate={maxBirthDate}
          />
        </Grid>

        <Grid mt={2}>
          <TextInput
            fullWidth
            onBlur={formik.handleBlur}
            label="Permanent Legal Residence"
            id="permanentLegalResidence"
            name="permanentLegalResidence"
            value={formik.values.permanentLegalResidence || ''}
            onChange={formik.handleChange}
            error={
              formik?.touched?.permanentLegalResidence &&
              Boolean(formik?.errors?.permanentLegalResidence)
            }
            helperText={
              formik?.touched?.permanentLegalResidence && formik?.errors?.permanentLegalResidence
            }
            maxLength={60}
          />
        </Grid>

        {customLabel && (
          <Grid mt={2}>
            <TextInput
              fullWidth
              onBlur={formik.handleBlur}
              label={customLabel}
              id="customLabel"
              name="customLabel"
              value={formik.values.customLabel}
              onChange={formik.handleChange}
              error={formik?.touched?.customLabel && Boolean(formik?.errors?.customLabel)}
              helperText={formik?.touched?.customLabel && formik?.errors?.customLabel}
            />
          </Grid>
        )}

        <Grid mb={3} mt={5}>
          <Typography variant="body" fontWeight={700} color={COLORS.black}>
            Address
          </Typography>
          <Grid mt={2}>
            <TextInput
              fullWidth
              label="Address"
              id="address"
              onBlur={formik.handleBlur}
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik?.errors?.address)}
              helperText={formik.touched.address && formik?.errors?.address}
              maxLength={60}
            />
          </Grid>
          <Grid mt={2}>
            <TextInput
              fullWidth
              onBlur={formik.handleBlur}
              label="City"
              id="city"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik?.touched?.city && Boolean(formik?.errors?.city)}
              helperText={formik?.touched?.city && formik?.errors?.city}
            />
          </Grid>
          <Grid container mt={2} display={'flex'} flexDirection={'row'} gap={1}>
            <Grid item flex={1}>
              <Select
                label="Country"
                title="Country"
                variant="filled"
                name="country"
                id="country"
                defaultValue=""
                fullWidth
                disableUnderline
                onBlur={formik.handleBlur}
                onChange={handleCountryChange}
                value={formik.values.country.id || ''}
                error={formik?.touched?.country && Boolean(formik?.errors?.country?.name)}
                helperText={formik?.touched?.country && formik?.errors?.country?.name}
                renderValue={(selected) => {
                  const selectedCountry = countries.find((country) => country.tid === selected);

                  return <div>{selectedCountry ? `${selectedCountry?.name} ` : ''}</div>;
                }}>
                {countries?.map(({ name, tid }, index) => (
                  <MenuItem key={index} value={tid}>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item flex={1}>
              <TextInput
                fullWidth
                label="State"
                id="state"
                name="state"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.state}
                error={formik?.touched?.state && Boolean(formik?.errors?.state)}
                helperText={formik?.touched?.state && formik?.errors?.state}
              />
            </Grid>
            <Grid item flex={1}>
              <TextInput
                fullWidth
                label="Zip Code"
                id="zipCode"
                name="zipCode"
                onBlur={formik.handleBlur}
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                error={Boolean(formik?.errors?.zipCode)}
                helperText={formik?.touched?.zipCode && formik?.errors?.zipCode}
              />
            </Grid>
          </Grid>
          <Grid mt={2}>
            <TextInput
              fullWidth
              label="Address 2 (Optional)"
              id="address2"
              name="address2"
              onBlur={formik.handleBlur}
              value={formik.values.address2}
              onChange={formik.handleChange}
              error={Boolean(formik?.errors?.address2)}
              helperText={formik?.errors?.address2}
              maxLength={60}
            />
          </Grid>
        </Grid>

        {!isUserInUpdateMode && !isUserInRenewMode && (
          <Grid mb={3} mt={5}>
            <Typography variant="body" fontWeight={700} color={COLORS.black}>
              Email
            </Typography>
            <Grid mt={2}>
              <TextInput
                fullWidth
                label="Email Address"
                id="email"
                name="email"
                onBlur={(e) => {
                  formik.handleBlur(e);
                  checkEmailAvailability(e.target.value);
                }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={
                  (formik.touched.email && Boolean(formik?.errors?.email)) ||
                  (emailError && emailError?.response?.data?.error)
                }
                helperText={
                  (formik.touched.email && formik?.errors?.email) ||
                  (emailError && emailError?.response?.data?.error)
                }
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      {isFetching && <CircularProgress color="inherit" size={20} />}
                      {!isFetching && !!(emailError && emailError?.response?.data?.error) && (
                        <CancelIcon style={{ color: COLORS.error }} />
                      )}
                      {!isFetching &&
                        !(emailError && emailError?.response?.data?.error) &&
                        formik.values.email && (
                          <CheckCircleOutlineIcon style={{ color: '#5CB5A2' }} />
                        )}
                    </React.Fragment>
                  )
                }}
                maxLength={60}
              />
            </Grid>
            <Grid mt={2}>
              <TextInput
                fullWidth
                label="Confirm Email Address"
                id="confirmEmailAddress"
                name="confirmEmailAddress"
                onBlur={formik.handleBlur}
                value={formik.values.confirmEmailAddress}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmEmailAddress && Boolean(formik?.errors?.confirmEmailAddress)
                }
                helperText={
                  formik.touched.confirmEmailAddress && formik?.errors?.confirmEmailAddress
                }
                maxLength={60}
              />
            </Grid>
          </Grid>
        )}
        <ActionButtonsContainer mt={7} container gap={1}>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmitFirstStep}
            disabled={isFetching}>
            Next
          </Button>
        </ActionButtonsContainer>
      </CenterFormGrid>
    </Grid>
  );
};

export default Step1;
