import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { decode, encode } from 'base-64';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/react';

if (!window.btoa) {
  window.btoa = encode;
}

if (!window.atob) {
  window.atob = decode;
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://8a768d4d13922721dfe5b1a136b54699@o4508937411559424.ingest.us.sentry.io/4508952396955648',
    tracesSampleRate: 0,
    integrations: [],
    beforeSend(event) {
      if (event.user) {
        event.user = { id: 'anonymous' };
      }

      return event;
    },
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ['https://www.globaltravelplus.com']
  });
}

ReactDOM.render(
  <>
    <ToastContainer
      position="bottom-right"
      autoClose={500}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
    <App />
  </>,
  document.getElementById('root')
);
