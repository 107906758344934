import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import { Formik, useFormik } from 'formik';

import { Grid, StepButton, Typography, useMediaQuery } from '@mui/material';

import {
  AccordionContainer,
  CenteredColumnContainer,
  ColorlibConnector,
  StepLabelStyled,
  StepStyled,
  StepperContainer
} from './Wizard.styles';
import Step1 from './Steps/Step1/Step1';
import Step2 from './Steps/Step2/Step2';
import { validationSchema2 } from './Steps/Step2/step2.scheme';
import {
  getAccordionDetails,
  getCountries,
  getStep3,
  getStepOneCustomFields,
  getUpdateUserWizardInfo
} from './wizard.api';
import { useQuery } from 'react-query';
import { useTheme } from '@emotion/react';
import {
  GENERAL_CLIENT,
  PLAN_RENEW_MODE,
  PLAN_TYPES,
  PLAN_UPADTE_MODE,
  SESSION_STORAGE_CLIENT,
  SESSION_STORAGE_PLAN_ID,
  STEPPER_STEPS
} from '../../utils/constants';
import step1FormikConfig from './Steps/Step1/step1.formik';
import step2FormikConfig from './Steps/Step2/step2.formik';
import { ColorlibStepIcon } from './stepper.utils';
import Step3 from './Steps/Step3/Step3';
import step3FormikConfig from './Steps/Step3/step3.formik';

import { getWizardPlans } from '../Plans/plans.api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import Step4 from './Steps/Step4/Step4';
import step4FormikConfig from './Steps/Step4/step4.formik';
import { checkSessionStorageItem } from '../../utils/sessionStorage';
import _ from 'lodash';
import ServerError from '../Static/ServerError';
import { CHILDREN, STEP1_PROPERTIES, STEP2_PROPERTIES, STEP3_PROPERTIES } from './constants';
import strings from '../../static/strings.json';

const stepperStyles = {
  width: {
    xl: '220px',
    lg: '180px',
    md: '180px',
    sm: '120px',
    xs: '90px'
  }
};

export default function Wizard() {
  const { client: clientParam } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const planId = queryParams.get('planId');
  const coverageLengthId = queryParams.get('coverageLengthId');
  const mode = queryParams.get('mode');

  const [client, hasClient] = checkSessionStorageItem(SESSION_STORAGE_CLIENT);

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isUserInUpdateMode = mode === PLAN_UPADTE_MODE;
  const isUserInRenewMode = mode === PLAN_RENEW_MODE;
  const isGuest = !isUserInRenewMode && !isUserInUpdateMode;

  React.useEffect(() => {
    if (clientParam && isGuest) {
      sessionStorage.setItem(SESSION_STORAGE_CLIENT, clientParam);
    }
  }, [clientParam, isGuest]);

  const questionStyles = {
    boxShadow: 'none',
    fontFamily: 'Inter',
    fontSize: isMobile ? '18px' : '20px',
    fontWeight: 500,
    pt: 3
  };

  const isTableteAndSmaller = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { data, isLoading, error } = useQuery(
    ['getStepOneCustomFields', client],
    getStepOneCustomFields
  );

  const {
    data: countries,
    isLoading: countriesIsLoading,
    error: countriesError
  } = useQuery('getCountries', getCountries);

  const {
    data: plansData,
    isLoading: plansIsLoading,
    error: plansError
  } = useQuery(['getWizardPlans'], () => getWizardPlans(client, hasClient));

  const {
    data: accordionData,
    isLoading: accordionIsLoading,
    refetch: refetchAccordionDetails,
    error: accordionError
  } = useQuery(['getAccordionDetails', planId], () => getAccordionDetails(planId));

  const planName = accordionData?.name == undefined ? 'Single' : accordionData?.name;

  const {
    data: step3Data,
    isLoading: step3IsLoading,
    error: step3Error
  } = useQuery(['getStep3', client, accordionData?.name], () => getStep3(planName, mode));

  const {
    data: updateWizardInfo,
    isLoading: updateWizardInfoIsLoading,
    error: updateWizardInfoError,
    refetch: updateWizardInfoFetch
  } = useQuery('getUpdateUserWizardInfo', () => getUpdateUserWizardInfo(), {
    enabled: false,
    retry: false
  });

  React.useEffect(() => {
    if (error || accordionError?.response?.status == 403) {
      sessionStorage.setItem(SESSION_STORAGE_CLIENT, GENERAL_CLIENT);
      navigate('/');
    }
  }, [navigate, error, accordionError?.response?.status]);

  React.useEffect(() => {
    if (isUserInRenewMode) {
      setActiveStep(2);
    }
  }, [isUserInRenewMode]);

  React.useEffect(() => {
    if (isUserInRenewMode || isUserInUpdateMode) {
      const client = sessionStorage.getItem(SESSION_STORAGE_CLIENT);
      const plan = sessionStorage.getItem(SESSION_STORAGE_PLAN_ID);

      if (plan !== planId || client != clientParam) navigate('/profile');
    }
  }, [isUserInRenewMode, isUserInUpdateMode, planId, clientParam, navigate]);

  React.useEffect(() => {
    if (isUserInUpdateMode || isUserInRenewMode) {
      updateWizardInfoFetch();
    }
  }, [isUserInUpdateMode, isUserInRenewMode, updateWizardInfoFetch]);

  const isPageLoading =
    isLoading || plansIsLoading || accordionIsLoading || countriesIsLoading || step3IsLoading;

  const pageError = error || plansError || accordionError || countriesError || step3Error;

  const step1UpdateInfo = updateWizardInfo ? _.pick(updateWizardInfo, STEP1_PROPERTIES) : null;
  const step2UpdateInfo = updateWizardInfo ? _.pick(updateWizardInfo, STEP2_PROPERTIES) : null;

  const step3UpdateInfo = updateWizardInfo
    ? _.pick(updateWizardInfo, STEP3_PROPERTIES(accordionData?.name))
    : null;

  const isDualPlan =
    plansData?.data?.find((item) => item.id === step3UpdateInfo?.plan)?.plan.toLowerCase() ===
    PLAN_TYPES.DUAL;

  const isFamilyPlan =
    plansData?.data?.find((item) => item.id === step3UpdateInfo?.plan)?.plan.toLowerCase() ===
    PLAN_TYPES.FAMILY;

  const step1InitialFormData = step1UpdateInfo
    ? {
        ...step1UpdateInfo,
        title:
          accordionData?.customer_title.find((item) => item.id === updateWizardInfo?.title) || '',
        professionalSuffix:
          accordionData?.customer_professional_suffix.find(
            (item) => item.id === updateWizardInfo.professionalSuffix
          ) || '',
        suffix:
          accordionData?.customer_suffix.find((item) => item.id === updateWizardInfo.suffix) || '',
        country: {
          id: step1UpdateInfo?.country,
          name: countries?.find((country) => country.tid === step1UpdateInfo?.country)?.name
        },
        confirmEmailAddress: step1UpdateInfo.email
      }
    : step1FormikConfig(data?.label).initialValues;

  const step2InitialFormData = step2UpdateInfo;

  const expatriatePlanSelected =
    step3Data?.expat_service?.find((item) => item.id === step3UpdateInfo?.expatriatePlan) || '';

  const selectedPlan = plansData?.data?.find((item) => item.id === step3UpdateInfo?.plan);

  const isPlanShort =
    selectedPlan?.id !=
    selectedPlan?.durations?.find((item) => item.trip_length === step3UpdateInfo.coverageLength)
      ?.id;

  const step3InitialFormData = step3UpdateInfo
    ? {
        ...step3UpdateInfo,
        plan: {
          id: selectedPlan?.id || '',
          name: selectedPlan?.plan
        },
        planDuration: {
          id: step3UpdateInfo?.planDuration,
          isShort: isPlanShort
        },
        effectiveDate: isUserInRenewMode ? '' : step3UpdateInfo.effectiveDate,
        coverageLength: {
          id: selectedPlan?.durations?.find(
            (item) => item.trip_length === step3UpdateInfo.coverageLength
          )?.id,
          name: step3UpdateInfo?.coverageLength
        },
        petPlan:
          step3Data?.pet_services?.find((item) => item.id === step3UpdateInfo?.petPlan) || '',
        spouseFirstName: isDualPlan
          ? step3UpdateInfo?.secondParticipantFirstName
          : step3UpdateInfo?.spouseFirstName,
        spouseLastName: isDualPlan
          ? step3UpdateInfo?.secondParticipantLastName
          : step3UpdateInfo?.spouseLastName,
        spouseBirthdate: isDualPlan
          ? step3UpdateInfo?.secondParticipantBirth
          : step3UpdateInfo?.spouseBirthdate,
        spouseMi: isDualPlan ? step3UpdateInfo?.secondParticipantMi : step3UpdateInfo?.spouseMi,
        expatriatePlan: expatriatePlanSelected
          ? {
              id: expatriatePlanSelected?.id,
              name: `${_.capitalize([accordionData?.name.toUpperCase()])} Coverage`
            }
          : '',
        travelLocation: {
          id: step3UpdateInfo?.travelLocation,
          name: countries?.find((country) => country.tid === step3UpdateInfo?.travelLocation)?.name
        },
        children: isFamilyPlan
          ? step3UpdateInfo?.children?.length <= 0
            ? [CHILDREN]
            : step3UpdateInfo?.children
          : [],
        pets: step3UpdateInfo?.pets?.map((pet) => {
          const modifiedType = step3Data?.pet_types?.find((item) => item.id == pet.type);
          const modifiedGender = step3Data?.pet_gender?.find((item) => item.id == pet.gender);

          return {
            ...pet,
            type: modifiedType,
            gender: modifiedGender
          };
        })
      }
    : null;

  const isRenewingPlanEnableInitialization =
    (isUserInRenewMode &&
      planId == plansData?.data?.find((item) => item.id === step3UpdateInfo?.plan)?.id) ||
    isUserInUpdateMode;

  const userHasBoughtPetPlan = !!step3UpdateInfo?.petPlan;
  const userHasBoughtExpatriatePlan = !!step3UpdateInfo?.expatriatePlan;
  const userNumberOfChildren = step3UpdateInfo?.children?.length;

  const numberOfPetsBought =
    step3Data?.pet_services?.find((item) => item.id === step3UpdateInfo?.petPlan)?.nb_of_pets || '';

  const step1 = useFormik({
    ...step1FormikConfig(data?.label),
    initialValues: !isGuest ? step1InitialFormData : step1FormikConfig(data?.label).initialValues
  });

  const step2 = useFormik(step2FormikConfig(!isGuest ? step2InitialFormData : null, true));

  const planSelected = plansData?.data.find((plan) => plan.id === planId)?.durations;

  const planDurationId = planSelected?.find(
    (plan) => plan?.id == coverageLengthId
  )?.plan_duration_id;

  const planDurationIsShort = planSelected?.find((plan) => plan?.id == coverageLengthId)?.is_short;

  const step3 = useFormik(
    step3FormikConfig(
      { id: planId, name: accordionData?.name },
      {
        id: planDurationId,
        isShort: planDurationIsShort
      },
      { id: coverageLengthId ?? '', name: '' },
      !isGuest ? step3InitialFormData : null,
      isRenewingPlanEnableInitialization
    )
  );

  const step4 = useFormik(step4FormikConfig(step1.values));

  const totalSteps = () => {
    return STEPPER_STEPS.length;
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { max_age, min_age, customer_professional_suffix, customer_suffix, customer_title } =
    accordionData || {};

  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (event) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    const handlePopState = () => {
      window.dispatchEvent(new Event('beforeunload'));
    };

    const handleHeaderItemClick = () => {
      window.dispatchEvent(new Event('beforeunload'));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    const headerItems = document.querySelectorAll('.MuiAppBar-root MuiButtonBase-root');

    headerItems.forEach((item) => {
      item.addEventListener('click', handleHeaderItemClick);
    });

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);

      headerItems.forEach((item) => {
        item.removeEventListener('click', handleHeaderItemClick);
      });
    };
  }, []);

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? STEPPER_STEPS?.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    setActiveStep(newActiveStep);
    window.scrollTo(0, 130);
  };

  const handleEditStepnavigation = (id) => {
    setActiveStep(id);
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 130);
  };

  const renderStepsBasedOnActiveId = () => {
    if (activeStep === 0)
      return (
        <Formik
          initialValues={step1.initialValues}
          validationSchema={() => step1FormikConfig(data?.label)}
          onSubmit={handleNext}>
          {() => (
            <Step1
              formik={step1}
              handleSubmit={handleNext}
              shouldShow={{ customLabel: data?.label }}
              ageLimit={{ max_age, min_age }}
              pickerOptions={{ customer_professional_suffix, customer_suffix, customer_title }}
              countries={countries}
              mode={mode}
            />
          )}
        </Formik>
      );
    if (activeStep === 1)
      return (
        <Formik
          initialValues={step2.initialValues}
          validationSchema={validationSchema2}
          onSubmit={handleNext}>
          <Step2 formik={step2} onPreviousClick={handleBack} handleSubmit={handleNext} />
        </Formik>
      );
    if (activeStep === 2)
      return (
        <Formik
          initialValues={step3.initialValues}
          // validationSchema={() => step3FormikConfig({ id, plan: accordionData?.name })}
          onSubmit={handleNext}>
          {() => (
            <Step3
              formik={step3}
              onPreviousClick={handleBack}
              handleSubmit={handleNext}
              plans={plansData?.data}
              selectedPlan={accordionData?.name}
              refetchAccordionDetails={refetchAccordionDetails}
              ageLimit={{ max_age, min_age }}
              countries={countries}
              mode={mode}
              userHasBoughtPetPlan={userHasBoughtPetPlan}
              numberOfPetsBought={numberOfPetsBought}
              userHasBoughtExpatriatePlan={userHasBoughtExpatriatePlan}
              userNumberOfChildren={userNumberOfChildren}
              updateWizardInfo={updateWizardInfo}
            />
          )}
        </Formik>
      );
    if (activeStep === 3)
      return (
        <Formik
          initialValues={step4.initialValues}
          validationSchema={() => step4FormikConfig(step1.values)}
          onSubmit={handleNext}>
          {() => (
            <Step4
              formik={step4}
              onPreviousClick={handleBack}
              plans={plansData?.data}
              formValues={{
                step1: step1.values,
                step2: step2.values,
                step3: step3.values
              }}
              customLabel={data?.label}
              handleChangeAddress={step1}
              handleEditStep={(id) => handleEditStepnavigation(id)}
              ageLimit={{ max_age, min_age }}
              countries={countries}
              mode={mode}
              numberOfPetsBought={numberOfPetsBought}
            />
          )}
        </Formik>
      );
  };

  return (
    <Grid container mt={14} mb={12}>
      {isPageLoading && <PageLoader />}
      {!isPageLoading && pageError && <ServerError />}
      {!isPageLoading && !pageError && (
        <>
          <Grid
            alignItems={'center'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            width={'100%'}>
            {accordionData?.client_logo_url && (
              <img
                src={process.env.REACT_APP_BACKEND_URL + accordionData?.client_logo_url}
                alt="client logo"
                style={{ width: '250px' }}
              />
            )}
            {!accordionIsLoading && !accordionError && (
              <AccordionContainer maxWidth={'xl'} width={'100%'} px={3}>
                {isUserInRenewMode && (
                  <Typography variant="heading4" fontWeight={500}>
                    {strings.RENEW_GLOBAL_TRAVEL_PLUS}
                  </Typography>
                )}
                {isUserInUpdateMode && (
                  <>
                    <Typography variant="heading4" fontWeight={500}>
                      {strings.UPDATE_PERSONAL_INFORMATION}
                    </Typography>
                    <Typography variant="body" color="#10182F80">
                      {strings.UPDATE_HELPER}
                    </Typography>
                  </>
                )}

                <Typography sx={questionStyles}>
                  {_.capitalize(accordionData?.name) + ' Plan'}
                </Typography>
                <Typography
                  variant="static_content"
                  dangerouslySetInnerHTML={{
                    __html: accordionData?.description
                  }}
                />
              </AccordionContainer>
            )}
          </Grid>
          <CenteredColumnContainer width={'100%'}>
            <StepperContainer maxWidth={'lg'}>
              <Stepper
                alternativeLabel={true}
                activeStep={activeStep}
                connector={<ColorlibConnector />}>
                {STEPPER_STEPS.map((label, index) => (
                  <StepStyled key={label}>
                    <StepButton color="inherit" onClick={handleStep(index)} sx={stepperStyles}>
                      <StepLabelStyled StepIconComponent={ColorlibStepIcon}>
                        {label}
                      </StepLabelStyled>
                    </StepButton>
                    <Typography variant="body" fontWeight={600}>
                      {isTableteAndSmaller ? '' : `Step ${index + 1}`}
                    </Typography>
                  </StepStyled>
                ))}
              </Stepper>
            </StepperContainer>
          </CenteredColumnContainer>
          <CenteredColumnContainer container>
            <Grid width={'100%'}>{renderStepsBasedOnActiveId()}</Grid>
          </CenteredColumnContainer>
        </>
      )}
    </Grid>
  );
}
